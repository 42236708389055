<template>

  <div 
    class="font-ptsans 2xl:text-2xl container py-20 pl-8 text-xl bg-white" 
    :class="[
      $screen.landscape 
        ? 'pr-16' 
        : 'pr-4'
    ]"
  >
    <localized-link
      :to="{ name: 'Home' }"
      class="inline-block mb-12"
    >
      <icon-base
        width="217"
        height="40"
        viewBox="0 0 236.5 43.5"
        class="w-auto h-10"
        style="color: #ed2027;"
        :title="
          $i18n.locale === 'uk'
            ? 'Студія «Зіна дизайн»'
            : 'Студия «Зина дизайн»'
        "
        :desc="
          $i18n.locale === 'uk'
            ? 'Веб-студія у Дніпрі: розробка сайтів, лендингів, інтернет-магазинів'
            : 'Веб-студия в Днепре: разработка сайтов, лендингов, интернет-магазинов'
        "
      ><icon-zina-logo /></icon-base>
    </localized-link>
    <div 
      class="relative flex flex-col justify-between"
      :class="[
        $screen.landscape 
          ? 'xl:flex-row' 
          : ''
      ]"
    >
      <!-- Col 1 -->
      <div 
        class="mb-12"
        :class="[
          $screen.landscape 
            ? 'flex-grow-0 xl:mb-0' 
            : ''
        ]"
      >
        <ul class="space-x-7 flex mb-6">
          <li>
            <a 
              href="tg://resolve?domain=romychvk"
              :title="
                $i18n.locale === 'uk'
                  ? 'Написати в Telegram'
                  : 'Написать в Telegram'
              "
            >
              <icon-base
                width="32"
                height="32"
                viewBox="0 0 32 32"
                class="w-8 h-auto"
                style="color: #039be5"
                :title="
                  $i18n.locale === 'uk'
                    ? 'Написати в Telegram'
                    : 'Написать в Telegram'
                "
              ><icon-telegram /></icon-base>
            </a>
          </li>
          <li>
            <a 
              class=""
              href="https://wa.me/380503481711"
              :title="
                $i18n.locale === 'uk'
                  ? 'Написати в WhatsApp'
                  : 'Написать в WhatsApp'
              "
            >
              <icon-base
                width="32"
                height="32"
                viewBox="0 0 32 32"
                class="w-8 h-8"
                style="color: #55cd6c"
                :title="
                  $i18n.locale === 'uk'
                    ? 'Написати в WhatsApp'
                    : 'Написать в WhatsApp'
                "
              ><icon-whatsapp /></icon-base>
            </a>
          </li>
          <li>
            <a 
              class=""
              href="viber://chat?number=+380503481711"
              :title="
                $i18n.locale === 'uk'
                  ? 'Написати в Viber'
                  : 'Написать в Viber'
              "
            >
              <icon-base
                width="32"
                height="32"
                viewBox="0 0 32 33.7"
                class="w-8 h-auto"
                style="color: #6e64c3"
                :title="
                  $i18n.locale === 'uk'
                    ? 'Написати в Viber'
                    : 'Написать в Viber'
                "
              ><icon-viber /></icon-base>
            </a>
          </li>
          <li>
            <a 
              class=""
              href="skype:romychvk?chat"
              :title="
                $i18n.locale === 'uk'
                  ? 'Написати в Skype'
                  : 'Написать в Skype'
              "
            >
              <icon-base
                width="32"
                height="32"
                viewBox="0 0 32.2 32.1"
                class="w-8 h-8"
                style="color: #2196f3"
                :title="
                  $i18n.locale === 'uk'
                    ? 'Написати в Skype'
                    : 'Написать в Skype'
                "
              ><icon-skype /></icon-base>
            </a>
          </li>
        </ul>
        <ul class="">
          <li class="footer-phone mb-3">
            <a 
              href="tel:+380503481711"
              :title="
                $i18n.locale === 'uk'
                  ? 'Зателефоновувати'
                  : 'Позвонить'
              "
            >
              <span class="footer-icons">
                <icon-base
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  class="text-zina-teal w-6 h-6 mb-2"
                  :title="
                    $i18n.locale === 'uk'
                      ? 'Зателефоновувати'
                      : 'Позвонить'
                  "
                ><icon-phone /></icon-base>
              </span>+<span class="hover:border-gray-700 border-b-2 border-white">38 050 348-17-11</span>
            </a>
          </li>
          <li class="mb-3">
            <a 
              href="mailto:mail@zina.design"
              :title="
                $i18n.locale === 'uk'
                  ? 'Написати електронною поштою'
                  : 'Написать по электронной почте'
              "
            >
              <span class="footer-icons">
                <icon-base
                  width="23"
                  height="18"
                  viewBox="0 0 23 18.1"
                  class="text-zina-teal w-6 h-auto"
                  :title="
                    $i18n.locale === 'uk'
                      ? 'Написати електронною поштою'
                      : 'Написать по электронной почте'
                  "
                ><icon-mail /></icon-base>
              </span>
              <span class="hover:underline">mail@zina.design</span>
            </a>
          </li>
          <li class="flex mb-3">
            <span class="footer-icons">
              <icon-base
                  width="17"
                  height="24"
                  viewBox="0 0 17.4 24"
                  class="text-zina-teal w-auto h-6"
                  :title="
                    $i18n.locale === 'uk'
                      ? 'Завітати до студії особисто'
                      : 'Зайти в студию лично'
                  "
                ><icon-map /></icon-base>
            </span>
            <span v-if="$i18n.locale === 'uk'">Дніпро, вул. Короленка 1,<br>офіс 401</span>
            <span v-if="$i18n.locale === 'ru'">Днепр, ул. Короленко 1,<br>офис 401</span>
          </li>
          <li>
            <span class="footer-icons">
              <icon-base
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  class="text-zina-teal w-6 h-6"
                  :title="
                    $i18n.locale === 'uk'
                      ? 'Графік роботи'
                      : 'График работы'
                  "
                ><icon-time /></icon-base>
            </span>{{ $t('working-time') }}
          </li>
        </ul>
      </div>
      <div class="divider"></div>
      <!-- Col-2 -->
      <div 
        class="flex mb-8"
        :class="[
          $screen.landscape 
            ? 'flex-grow xl:justify-center xl:mb-0' 
            : 'flex-grow-0'
        ]"
      >
        <div class="max-w-max">
          <div class="footer-menu-title">
            <!-- <a :href="$i18n.locale === 'uk' ? '/uk/services/' : '/ru/services/'"> -->
              {{ $t('Services') }}
            <!-- </a> -->
          </div>
          <div 
            class="flex"
            :class="[
              $screen.landscape 
                ? '' 
                : 'flex-wrap'
            ]"
          >
            <ul class="mr-12">
              <li class="footer-menu-item">
                <a :href="$i18n.locale === 'uk' ? '/uk/stvorennya-saitu/' : '/ru/sozdanie-saita/'">
                  {{ $t('Website development') }}
                </a>
              </li>
              <li class="footer-menu-item">
                <a :href="$i18n.locale === 'uk' ? '/uk/stvorennya-lendingu/' : '/ru/sozdanie-lendinga/'">
                  {{ $t('Landing page creation') }}
                </a>
              </li>
              <li class="footer-menu-item">
                <a :href="$i18n.locale === 'uk' ? '/uk/stvorennya-internet-magazinu/' : '/ru/sozdanie-internet-magazina/'">
                  {{ $t('Creation of online stores') }}
                </a>
              </li>
              <li class="footer-menu-item">
                <!-- <a :href="$i18n.locale === 'uk' ? '/uk/site-design/' : '/ru/site-design/'"> -->
                  {{ $t('Website design') }}
                <!-- </a> -->
              </li>
            </ul>
            <ul class="">
              <li class="footer-menu-item">
                <!-- <a :href="$i18n.locale === 'uk' ? '/uk/domain-registration/' : '/ru/domain-registration/'"> -->
                  {{ $t('Domain registration') }}
                <!-- </a> -->
              </li>
              <li class="footer-menu-item">
                <!-- <a :href="$i18n.locale === 'uk' ? '/uk/hosting/' : '/ru/hosting/'"> -->
                  {{ $t('Website hosting') }}
                <!-- </a> -->
              </li>
              <li class="footer-menu-item">
                <!-- <a :href="$i18n.locale === 'uk' ? '/uk/seo/' : '/ru/seo/'"> -->
                  {{ $t('SEO promotion') }}
                <!-- </a> -->
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <!-- Col-3 -->
      <div class="flex-grow-0">
        <div class="">
          <div class="footer-menu-title">
            <a :href="$i18n.locale === 'uk' ? '/uk/about/' : '/ru/about/'">
              {{ $t('About') }}
            </a>
          </div>
          <div 
            class="flex"
            :class="[
              $screen.landscape 
                ? '' 
                : 'flex-wrap'
            ]"
          >
            <ul class="mr-12">
              <li class="footer-menu-item">
                <a :href="$i18n.locale === 'uk' ? '/uk/team/' : '/ru/team/'">
                  {{ $t('Team') }}
                </a>
              </li>
              <li class="footer-menu-item">
                <a :href="$i18n.locale === 'uk' ? '/uk/drupal/' : '/ru/drupal/'">
                  {{ $t('Technologies') }}
                </a>
              </li>
              <li class="footer-menu-item">
                <a :href="$i18n.locale === 'uk' ? '/uk/awards/' : '/ru/awards/'">
                  {{ $t('Awards') }}
                </a>
              </li>
              <li class="footer-menu-item">
                <a :href="$i18n.locale === 'uk' ? '/uk/feedback/' : '/ru/feedback/'">
                  {{ $t('Feedback') }}
                </a>
              </li>
            </ul>
            <ul class="">
              <li class="footer-menu-item">
                <a :href="$i18n.locale === 'uk' ? '/uk/portfolio/' : '/ru/portfolio/'">
                  {{ $t('Portfolio') }}
                </a>
              </li>
              <li class="footer-menu-item">
                <a :href="$i18n.locale === 'uk' ? '/uk/price/' : '/ru/price/'">
                  {{ $t('Prices') }}
                </a>
              </li>
              <li class="footer-menu-item">
                <a :href="$i18n.locale === 'uk' ? '/uk/guarantee/' : '/ru/guarantee/'">
                  {{ $t('Guarantee') }}
                </a>
              </li>
              <li class="footer-menu-item">
                <a :href="$i18n.locale === 'uk' ? '/uk/contacts/' : '/ru/contacts/'">
                  {{ $t('Contacts') }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- Col 3 -->
      </div>
    </div>

  </div>

  <div class="copyright">
    <p class="pt-8 text-base text-center text-white">
      © <a class="hover:underline" href="https://zina.design">{{ $t('Zina Design Studio') }}</a>, 1998-{{new Date().getFullYear()}}
    </p>
  </div>

</template>

<script>
import { defineAsyncComponent } from "vue";
const IconBase = defineAsyncComponent(() => import("@/components/IconBase"));
const IconZinaLogo = defineAsyncComponent(() => import("@/components/icons/IconZinaLogo"));
const IconTelegram = defineAsyncComponent(() => import("@/components/icons/IconTelegram"));
const IconWhatsapp = defineAsyncComponent(() => import("@/components/icons/IconWhatsapp"));
const IconViber = defineAsyncComponent(() => import("@/components/icons/IconViber"));
// const IconSkype = defineAsyncComponent(() => import("@/components/icons/IconSkype"));
const IconPhone = defineAsyncComponent(() => import("@/components/icons/IconPhone"));
const IconMail = defineAsyncComponent(() => import("@/components/icons/IconMail"));
const IconMap = defineAsyncComponent(() => import("@/components/icons/IconMap"));
const IconTime = defineAsyncComponent(() => import("@/components/icons/IconTime"));
// import IconBase from '@/components/IconBase.vue'
// import IconZinaLogo from '@/components/icons/IconZinaLogo.vue'
// import IconTelegram from '@/components/icons/IconTelegram.vue'
// import IconWhatsapp from '@/components/icons/IconWhatsapp.vue'
// import IconViber from '@/components/icons/IconViber.vue'
// import IconSkype from '@/components/icons/IconSkype.vue'
// import IconPhone from '@/components/icons/IconPhone.vue'
// import IconMail from '@/components/icons/IconMail.vue'
// import IconMap from '@/components/icons/IconMap.vue'
// import IconTime from '@/components/icons/IconTime.vue'
export default {
  name: "Footer",
  components: {
    IconBase,
    IconZinaLogo,
    IconTelegram,
    IconWhatsapp,
    IconViber,
    // IconSkype,
    IconPhone,
    IconMail,
    IconMap,
    IconTime,
  }
};
</script>

<style lang="scss" scoped>
.footer {
  // position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  // left: 0;
  // bottom: 0;
  width: 100%;
  // background-color: red;
  color: white;
  text-align: center;
}
.footer-icons {
  @apply inline-block w-8;
}
.footer-menu-title {
  @apply font-roboto mb-6 font-light leading-none uppercase;
  font-size: 32px;
  a {
    @apply hover:border-gray-700 border-b-2 border-white;
  }
}
.footer-menu-item {
  @apply mb-3;
  a {
    @apply hover:underline;
  }
}
.footer-phone {
  font-size: 31px;
  @media (min-width: 360px) {
    font-size: 32px;
  }
}
.divider {
  @apply flex-grow;
  background: linear-gradient(#000, #000) no-repeat center/1px 100%;
}
.copyright {
  background: transparent linear-gradient(93deg, #8969CB 0%, #76DDFE 100%) 0% 0% no-repeat padding-box;
  height: 103px;
}
</style>